import React from 'react'
import { Link } from 'gatsby'
import { Section, SEO } from '../components'
import Layout from '../layouts'

export default function MentorshipPage() {
  return (
    <Layout>
      <SEO
        title="Mentorship Program"
        description="Welcome to the Pure Func Mentorship program! We are excited to work closely with inspiring developers looking to start a new career in web development!"
        pathname="/mentorship"
      />
      <Section isHero theme="primary">
        <div className="grid">
          <div>
            <h2 className="margin-top-0">Thank you for your interest in our mentorship program!</h2>
            <p className="large">
              We are currently taking applications for our mentorship program. If you are interested in learning more
              about our program, please fill out the form below and we will be in touch as soon as possible.
            </p>
          </div>
        </div>
      </Section>
      <Section>
        <div className="grid grid--split">
          <div></div>
          <div>
            <h3 className="margin-top-0">How the mentorship program works</h3>
            <ul className="disc">
              <li>Every 3 months we will select up to 3 individuals to join our program for the next 3 months.</li>
              <li>
                Individuals will be selected after an initial interview. No previous coding skills are required but we
                highly recommend self-study in parallel with our program for best results.
              </li>
              <li>
                During the 3 month program, the team will work closely together, mimicking a real-world business
                scenario to make a website from start to finish.
              </li>
              <li>
                The aim of the program is to prepare students for their first junior web developer position by giving a
                fully immersive experience. The experience will focus not only on hard skills needed to build a website
                but also simulate the experience of working at an agency.
              </li>
              <li>
                We will have both weekly team meetings to discuss project progress and 1 on 1 meetings for personalized
                learning.
              </li>
              <li>
                At the end of the program, everyone will learn the best way to add the experience to their resume so
                that they have a one-up when applying for their first entry-level position.
              </li>
            </ul>
            <div className="margins">
              <Link to="/contact" className="cta">
                Contact Us To Apply
              </Link>
            </div>
          </div>
        </div>
      </Section>
    </Layout>
  )
}
